import * as React from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { connect } from "react-redux";
import moment from "moment";
import API from "../utils/API";
import { default as UserAPI } from "../../../utils/API";
import { Alert, Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";
import { getAllUsers, loadMoreUsers } from "../../../store/actions/AllUsersAction";
import TableLayout from "../Common/UserTableLayout";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../../components/Common/Spinner";
import { useSelector } from "react-redux";
import store from "../../../store/configureStore";
import { groupDelete, groupsCollectionPath, userCollectionPath, userDelete } from "../Common/Constants";
import GroupTableLayout from "../Common/GroupTableLayout";
import { fetchAllGroups, fetchAllGroupsLoadMore } from "../store/actions/admin";


function UserDatagriddata({ allUsers,refreshUserList, allUsersCount, fetchAllGroups, fetchAllGroupsLoadMoreAction, grouplazyloader, allGroupsCount, allgroups, roles, selection, fetchAllUserAction, filterData, fetchAllUserLoadMoreAction, usersPageNumber, lazyloader }) {
    const [success, setSuccess] = React.useState(false)
    const [alert, setAlert] = React.useState({
        active: false,
        user: '',
        message: ''
    });
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [deleteData, setDeleteData] = React.useState({});
    const [hasMore, setHasMore] = React.useState(true);
    const [groupHasMore, setgroupHasMore] = React.useState(true);

    const userId = useSelector(() => store.getState().profileData.id)
    const loadMore = () => {
        if (lazyloader === false) {
            setHasMore(false)
        }
        setTimeout(() => {
            fetchAllUserLoadMoreAction({ PageNumber: usersPageNumber })
        }, 1000);
    }

    const groupLoadMore = () => {
        if (grouplazyloader === false) {
            setgroupHasMore(false)
        }
        setTimeout(() => {
            fetchAllGroupsLoadMoreAction({ PageNumber: usersPageNumber })
        }, 1000);
    }
    const { t } = useTranslation()

    const Are_You_Sure = t("Are_You_Sure")
    const Cancel = t("Cancel")
    const Delete = t("Delete")
    const You_want_to_delete = t("You_want_to_delete")
    const user = t("User")
    const ques_mark = t("?")
    const Cannot_Delete_Yourself = t("Cannot_Delete_Yourself")


    useEffect(()=>{
        fetchAllUserAction({
            PageNumber: 1,
            PageSize: 20
        })
    },[])

    const handleClose = () => {
        setDialogOpen(false);
        setDeleteData({})
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            user: '',
            message: ''
        })
    }

    const handleRoleChange = (user, roleId) => {
        API.updateUserRole(
            {
                UserId: user.Id,
                RoleId: roleId
            }
        ).then(res =>
            res.status == 201 &&
            setSuccess(true),
            setAlert({ active: true, user: user.Name, type: 'role changed', severity: 'success', message: `User ${user.Name}'s Role Changed!` })
        )
    }

    const handleDeleteDialog = (data, isTrue, deleteFor) => {
        if (deleteFor === userDelete) {
            var model = {
                content: `${You_want_to_delete} ${user} ${data.Name} ${ques_mark}`,
                Id: data.Id,
                user: data.Name,
                deleteFor: deleteFor
            }
            setDeleteData(model)
            setDialogOpen(isTrue)
        }

        if (deleteFor === groupDelete) {
            var model = {
                content: `${You_want_to_delete} group ${data.Name} ${ques_mark}`,
                Id: data.Id,
                user: data.Name,
                deleteFor: deleteFor
            }
            setDeleteData(model)
            setDialogOpen(isTrue)
        }
    }

    const handleDelete = async () => {
        if (deleteData.deleteFor === groupDelete) {
            API.deleteGroup(deleteData.Id).then(res => {
                if (res.status === 201) {
                    fetchAllGroups({
                        PageNumber: 1,
                        PageSize: 20
                    })
                }
            }).finally(() => handleClose())
        }

        if (deleteData.deleteFor === userDelete) {
            if (deleteData.Id === userId) {
                setAlert({ active: true, user: deleteData.user, message: Cannot_Delete_Yourself })
                handleClose()
            }
            else {
                await UserAPI.deleteUserProfile(deleteData.Id).then(res => {
                    if (res.status === 201) {
                        setAlert({ active: true, user: deleteData.user, message: `User ${deleteData.user} Deleted!` })
                        fetchAllUserAction({
                            PageNumber: 1,
                            PageSize: 20
                        })
                        handleClose()
                    }
                })
            }
        }
    }
    React.useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    React.useEffect(() => {
        setTimeout(() => {
            setSuccess(false)
        }, 3000);
    }, [success])

    const handleUserSelection = (e) => {
        selection(e)
    }

    const groupColumns = [
        {
            field: "Name",
            headerName: "Name",
            sortable: false,
            width: 400,
            align: document.body.dir === 'ltr' ? "left" : "right",

        },
        {
            field: "Status", headerName: "Status", width: 100, sortable: false, align: 'center',
        },
        {
            field: "ActiveCourse", headerName: "Active Course", width: 150, sortable: false, align: 'center',
        },
        {
            field: "UserCount", headerName: "Users in Count", width: 150, sortable: false, align: 'center'
        },
        {
            field: "CreatedOn", headerName: "Created On", width: 150, sortable: false, align: document.body.dir === 'ltr' ? "center" : "right",
        },

        // {
        //     field: "Info",
        //     headerName: "Info",
        //     sortable: false,
        //     width: 100,
        //     disableClickEventBubbling: true,
        //     renderCell: (params) => {
        //         return (
        //             <div
        //                 className="d-flex justify-content-between align-items-center"
        //                 style={{ cursor: "pointer" }}
        //             >
        //                 <svg width="39" height="39" xmlns="http://www.w3.org/2000/svg">
        //                     <g fill="none" fill-rule="evenodd" opacity=".496">
        //                         <rect
        //                             className="svg-stroke"
        //                             stroke-width=".9"
        //                             x=".45"
        //                             y=".45"
        //                             width="38.1"
        //                             height="38.1"
        //                             rx="7.2"
        //                         />
        //                         <g className="svg-for-menu">
        //                             <g fill-rule="nonzero">
        //                                 <path d="m13.426 24.603 3.004-.814 8.26-8.26 1.549-1.55-2.417-2.416-1.574 1.575-8.217 8.216-.605 3.249zm9.628-14.029c.403-.402 1.066-.384 1.493.043l2.639 2.639c.421.421.437 1.097.042 1.492L17.202 24.774l-.253.146L12.969 26c-.537.145-.984-.34-.892-.835l.827-4.44 10.15-10.15zM13.275 29h8.33c.451 0 .817-.308.817-.687 0-.38-.366-.687-.817-.687h-8.33c-.452 0-.817.307-.817.687 0 .38.365.687.817.687z" />
        //                             </g>
        //                             <path d="m13.72 20.719-.736 4.038 4.102-.723 9.686-9.986-2.995-2.813z" />
        //                         </g>
        //                     </g>
        //                 </svg>
        //             </div>
        //         );
        //     },
        // },
        // {
        //     field: "Edit",
        //     headerName: "Edit",
        //     sortable: false,
        //     width: 100,
        //     align: 'center',
        // },
        {
            field: "Delete",
            headerName: "Delete",
            sortable: false,
            width: 100,
            align: 'center',
        },
    ];

    const columns = [
        {
            field: "Name",
            headerName: "Name",
            sortable: false,
            width: 400,
            align: document.body.dir === 'ltr' ? "left" : "right",

        },
        {
            field: "Status", headerName: "Status", width: 100, sortable: false, align: 'center',
        },
        {
            field: "ActiveCourse", headerName: "Active Course", width: 150, sortable: false, align: 'center',
        },
        {
            field: "LastActive", headerName: "Last Active", width: 150, sortable: false, align: 'center'
        },
        {
            field: "Role", headerName: "Role", width: 150, sortable: false, align: document.body.dir === 'ltr' ? "left" : "right",
        },
        // {
        //     field: "Info",
        //     headerName: "Info",
        //     sortable: false,
        //     width: 100,
        //     disableClickEventBubbling: true,
        //     renderCell: (params) => {
        //         return (
        //             <div
        //                 className="d-flex justify-content-between align-items-center"
        //                 style={{ cursor: "pointer" }}
        //             >
        //                 <svg width="39" height="39" xmlns="http://www.w3.org/2000/svg">
        //                     <g fill="none" fill-rule="evenodd" opacity=".496">
        //                         <rect
        //                             className="svg-stroke"
        //                             stroke-width=".9"
        //                             x=".45"
        //                             y=".45"
        //                             width="38.1"
        //                             height="38.1"
        //                             rx="7.2"
        //                         />
        //                         <g className="svg-for-menu">
        //                             <g fill-rule="nonzero">
        //                                 <path d="m13.426 24.603 3.004-.814 8.26-8.26 1.549-1.55-2.417-2.416-1.574 1.575-8.217 8.216-.605 3.249zm9.628-14.029c.403-.402 1.066-.384 1.493.043l2.639 2.639c.421.421.437 1.097.042 1.492L17.202 24.774l-.253.146L12.969 26c-.537.145-.984-.34-.892-.835l.827-4.44 10.15-10.15zM13.275 29h8.33c.451 0 .817-.308.817-.687 0-.38-.366-.687-.817-.687h-8.33c-.452 0-.817.307-.817.687 0 .38.365.687.817.687z" />
        //                             </g>
        //                             <path d="m13.72 20.719-.736 4.038 4.102-.723 9.686-9.986-2.995-2.813z" />
        //                         </g>
        //                     </g>
        //                 </svg>
        //             </div>
        //         );
        //     },
        // },
        // {
        //     field: "Edit",
        //     headerName: "Edit",
        //     sortable: false,
        //     width: 100,
        //     align: 'center',
        // },
        {
            field: "Delete",
            headerName: "Delete",
            sortable: false,
            width: 100,
            align: 'center',
        },
    ];

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity="success" style={{ position: "absolute", top: 20 }}>
                    {`${alert.message}`}
                </Alert>
            </Backdrop>
            <Dialog open={dialogOpen} onClose={handleClose} className="delete-confirmation">
                <DialogTitle>{Are_You_Sure}</DialogTitle>
                <Divider className="confirmation-popup-divider" />
                <DialogContent>
                    <DialogContentText>
                        {deleteData.content}
                    </DialogContentText>
                </DialogContent>
                <Divider className="confirmation-popup-divider" />
                <DialogActions>
                <button
                        className="primary-button ter-btn ad-share-btn red-button"
                        style={{ backgroundColor: '#043677', color: 'white' }}
                        onClick={handleDelete}
                    >
                        {Delete}
                    </button>
                    <button
                        className="primary-button ter-btn"
                        onClick={handleClose}
                    >
                        {Cancel}
                    </button>
                </DialogActions>
            </Dialog>

            {window.location.pathname.split('/').pop() === userCollectionPath &&
                <InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={filterData.Count > 0 ? filterData.Data : allUsers.Users.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={allUsersCount > allUsers.Users.length && <Spinner />}
                    endMessage
                >
                    <TableLayout columns={columns} data={filterData.Count > 0 ? filterData.Data : allUsers.Users} roles={roles} delete={handleDeleteDialog} selected={handleUserSelection} changeRole={handleRoleChange} />
                </InfiniteScroll>
            }

            {window.location.pathname.split('/').pop() === groupsCollectionPath &&
                <InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={filterData.Count > 0 ? filterData.Data : allgroups.Groups.length}
                    next={groupLoadMore}
                    hasMore={groupHasMore}
                    loader={allGroupsCount > allgroups.Groups.length && <Spinner />}
                    endMessage
                >
                    <GroupTableLayout columns={groupColumns} data={filterData.Count > 0 ? filterData.Data : allgroups.Groups} roles={roles} delete={handleDeleteDialog} selected={handleUserSelection} changeRole={handleRoleChange} />
                </InfiniteScroll>
            }

        </>
    );
}

const mapStateToProps = (state) => ({
    allUsers: state.allusers.allUsers,
    allgroups: state.groups.allGroups,
    allUsersCount: state.allusers.allUsers.Count,
    allGroupsCount: state.groups.allGroups.Count,
    roles: state.roles.roles,
    lazyloader: state.allusers.lazyloader,
    grouplazyloader: state.allusers.lazyloader,
    usersPageNumber: state.allusers.userPageNumber,
    groupPageNumber: state.groups.groupPageNumber,
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAllUserAction: getAllUsers,
        fetchAllUserLoadMoreAction: loadMoreUsers,
        fetchAllGroups: fetchAllGroups,
        fetchAllGroupsLoadMoreAction: fetchAllGroupsLoadMore
    }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDatagriddata);
