import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
    IconButton,

    List,
    ListItem,

} from "@mui/material";
import { useSelector } from "react-redux";
import store from "../../store/configureStore";
import API from "../../utils/API";
import GenerateProfilePic from "../../components/Common/GenerateProfilePictureIntitals";
import { margin } from "@mui/system";



export default function CertificateShareUser(props) {
    console.log(props)
    const [open, setOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        selectedUser.splice(0, selectedUser.length);
    };

    const userId = useSelector(() => store.getState().profileData.id);

    //   const handleShareButton = () => {
    //     handleOpen();
    //   };

    const handleSelectUser = (id) => {
        setSelectedUser([
            ...selectedUser,
            ...props.users.Users.filter((a) => a.Id == id).slice(0),
        ]);
    };

    const handleRemoveUser = (id) => {
        console.log(id);
        // selectedUser.indexOf(id)
        selectedUser.splice(selectedUser.indexOf(id), 1);
        setSelectedUser([...selectedUser]);
    };

    const handleShare = async() => {
       await selectedUser.map(i => 
            API.shareMyCertificate({
                sharedBy: userId,
                sharedWith: i.Id,
                certificateId: props.selectedCertificate.Id,
            }))
        props.closeModal()
        setSelectedUser([])
        setTimeout(() => {
            props.alert()
        }, 300);
    };

    function SearchUsersComponent({ type }) {
        return (
            <>
                <div>
                    <div className="admin-share-con-user lms-flex">
                        <input type="text" placeholder="search" />
                    </div>
                    <List sx={{ width: "100%", bgcolor: "background.paper" }} className="admin-share-list">
                        {type === "select" ? (
                            props.users.Users
                                .filter((j) => j.Id != userId && !selectedUser.includes(j))
                                .map((i) => (
                                    <ListItem
                                        key={i.Id}
                                        disableGutters
                                        secondaryAction={
                                            <IconButton aria-label="comment">
                                                <button
                                                    className="share-btn-collection"
                                                    onClick={() => {
                                                        handleSelectUser(i.Id);
                                                    }}
                                                >
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.0967 10.5999L10.436 10.6102V15.2813C10.4463 15.5201 10.2491 15.7173 10 15.7173C9.87544 15.7173 9.76126 15.6654 9.6886 15.5927C9.61593 15.5201 9.56403 15.4059 9.56403 15.2813V10.6102H4.89297C4.76841 10.6102 4.65423 10.5583 4.58157 10.4857C4.5089 10.413 4.457 10.2988 4.457 10.1743C4.44662 9.93554 4.64385 9.73832 4.89297 9.73832L9.55365 9.72794L9.56403 5.06725C9.55365 4.82851 9.75088 4.63129 10 4.63129C10.2387 4.62091 10.436 4.81813 10.436 5.06725V9.73832L15.0967 9.72794C15.3354 9.71756 15.5326 9.91478 15.5326 10.1639C15.543 10.4026 15.3458 10.5999 15.0967 10.5999Z" fill="#379E53" />
                                                    </svg>
                                                </button>
                                            </IconButton>
                                        }
                                    >
                                        <div className="">
                                            <div className="user-inf lms-flex">
                                                <div className="col-2 share-user">
                                                    {
                                                        i.ProfileImageUrl === '' || i.ProfileImageUrl === null ?
                                                            <img src={GenerateProfilePic(i.Name)} className='profile-avatar' />
                                                            :
                                                            <img src={i.ProfileImageUrl} className='profile-avatar' />
                                                    }
                                                </div>
                                                <div className="share-user-inf">
                                                    <div className="">
                                                        <h3 className="">{`${i.Name}`}</h3>
                                                    </div>
                                                    <p className="email">{i.Email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                ))
                        ) : selectedUser.length > 0 ? (
                            selectedUser
                                .filter((j) => j.Id != userId)
                                .map((i) => (
                                    <ListItem
                                        key={i.Id}
                                        disableGutters
                                        secondaryAction={
                                            <IconButton aria-label="comment">
                                                <button
                                                    className="share-btn-collection"
                                                    onClick={() => {
                                                        handleRemoveUser(i.Id);
                                                    }}
                                                >
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="#C93329" />
                                                        <path d="M4.5 10C4.5 9.72386 4.72386 9.5 5 9.5H15C15.2761 9.5 15.5 9.72386 15.5 10C15.5 10.2761 15.2761 10.5 15 10.5H5C4.72386 10.5 4.5 10.2761 4.5 10Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </IconButton>
                                        }
                                    >
                                        <div className="">
                                            <div className="user-inf lms-flex">
                                                <div className="col-2 share-user">
                                                    {
                                                        i.ProfileImageUrl === '' || i.ProfileImageUrl === null ?
                                                            <img src={GenerateProfilePic(i.Name)} className='profile-avatar' />
                                                            :
                                                            <img src={i.ProfileImageUrl} className='profile-avatar' />
                                                    }
                                                </div>
                                                <div className="share-user-inf">
                                                    <div className="">
                                                        <h3 className="">{`${i.Name}`}</h3>
                                                    </div>
                                                    <p className="email">{i.Email}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </ListItem>
                                ))
                        ) : (
                            <div>No Users Selected</div>
                        )}
                    </List>
                </div>


            </>
        );
    }

    return (
        <Tabs>
            <TabList>
                <Tab>Users</Tab>
            </TabList>

            <TabPanel>
                <div class="managment-container lms-flex">
                    <div className="admin-share-user-left share-cirfificate-left">
                        <SearchUsersComponent type={"select"} />
                    </div>
                    <div class="admin-share-user-right share-cirfificate-right">
                        <div className="share-title">Selected Users</div>
                        <SearchUsersComponent type={"selected"} />
                        <div className="lms-flex share-buttons ">
                            <div className="col-5 d-flex"><button
                                className="primary-button ter-btn"
                                onClick={() => { setSelectedUser([]), props.closeModal() }}
                            >
                                Cancel
                            </button></div>
                            <button
                                className="primary-button sec-btn ad-share-btn"
                                disabled={selectedUser.length > 0 ? false : true}
                                onClick={handleShare}
                            >
                                Share
                            </button>
                        </div>

                    </div>
                </div>
            </TabPanel>
        </Tabs>
    );
}