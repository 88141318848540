import React, { useState, useRef, useEffect } from 'react';
import API from '../utils/API';
import store from '../../../store/configureStore';
import { fetchAdminCourse } from '../store/actions/admin';
import { connect , useSelector} from 'react-redux';
import Spinner from '../../../components/Common/Spinner';
import HorizontalStepper from '../../../components/Common/HorizontalStepper';
import moment from "moment";
import { liveCourseProviders, sessionPayload, webinarJoinLinks } from '../../../components/Common/Constants';
import {documentPath, extension, dialogType, validationLimits, currentTime, CourseType } from '../Common/Constants';
import { bindActionCreators } from 'redux';
import {Backdrop,Alert} from '@mui/material';
import BreadcrumbsNav from '../Common/BreadcrumbsNav';
import { useNavigate } from 'react-router-dom';
import { sessionCreateLogicAppRequest } from '../../../components/SessionCreateUtil/Util';
import getSessionPayloadBody from '../../../components/SessionCreateUtil/sessionPayload';
import CourseCreation from '../Common/CourseCreation';
import Uploadwithsas from '../Common/Uploadwithsas';

const LiveCourseForm = (props) => {
    const sessionThumbnailRef = useRef(null)
    const thumbnailFile = React.useRef(null)
    const documentRef = React.useRef(null)
    const [modalLoader, setModalLoader] = useState(false);
    const [courseId, setCourseId] = React.useState(null);

    const [sessionIndex, setSessionIndex] = React.useState(null)
    const [sessionName, setSessionName] = React.useState('')
    const [sessionDate, setSessionDate] = React.useState('')
    const [sessionStartTime, setSessionStartTime] = React.useState('')
    const [sessionEndTime, setSessionEndTime] = React.useState('')
    const [sessionDocumnet, setSessionDocumnet] = React.useState([])
    const [sessionDescription, setSessionDescription] = React.useState('')
    const [sessions, setSessions] = React.useState([])
    const [sessionThumbnailPreview, setSessionThumbnailPreview] = React.useState('');
    const [sessionThumbnail, setSessionThumbnail] = React.useState([]);
    const [isAddSessions, setAddSession] = React.useState(false);
    const [isSesionsListActive, setSetionsList] = React.useState(false);


    const [sessionNameError, setSessionNameError] = React.useState(false)
    const [sessionDescriptionError, setSessionDescriptionError] = React.useState(false)
    const [sessionThumnbnailError, setSessionThumbnailError] = React.useState(false)
    const [sessionDateError, setSessionDateError] = React.useState(false)
    const [sessionTimeError, setSessionTimeError] = React.useState(false)
    const [sessionDocumnetError, setSessionDocumnetError] = React.useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [formFilled, setFormFilled] = useState(false)

    const childRef = useRef();

    const triggerChildCreateCourse = () => {
        if (childRef.current) {
          childRef.current.triggerCreateLiveCourse();
        }
      };

      const handleCourseId = (id) => {
        setCourseId(id);
      };

    const [editSessionOnList, setEditSessionOnList] = React.useState(false);

    const navigate = useNavigate();

    const userId = useSelector(() => store.getState().profileData.id)
    const userMail = useSelector(() => store.getState().profileData.Email)
    const userName = useSelector(() => store.getState().profileData.Name)

    const [activeStep, setActiveStep] = useState(0);

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleReset = () => {
        setActiveStep(1)
        setSessionName('')
        setSessionDescription('')
        setSessionDate('')
        setSessionStartTime('')
        setSessionEndTime('')
        setSessionDocumnet([])
        setSessionThumbnail([])
        setSessionThumbnailPreview('')
        setSessionDate(new Date().toLocaleDateString('en-CA'))
        setSessionStartTime(moment().add(15, 'minutes').format('HH:mm'))
        setSessionEndTime(moment().add(1, 'hour').add(15, 'minutes').format('HH:mm'))
    }

    const [alert, setAlert] = useState({
        active: false,
        severity: '',
        message: ''
    })

    const handleSessionThumbnail = (event) => {
        setSessionThumbnail(event)
        const objectUrl = URL.createObjectURL(event[0])
        setSessionThumbnailPreview(objectUrl)
        setSessionThumbnailError(false)
    }

    const handleSessionThumbnailClick = () => {
        sessionThumbnailRef.current?.click()
    }


    const handleDocumentUpload = (event) => {
        setSessionDocumnet(event)
    }

    useEffect(()=>{
        if (sessionDocumnet.length > 0 && sessionDocumnet[0].name.trim().length > 25) {
            setSessionDocumnetError(true)
        }
        else{
            setSessionDocumnetError(false)
        }
    })


    const handleDocumnetOpen = () => {
        documentRef.current?.click()
    }

    const handleCreateSessions = () => {
        setModalLoader(true)
        if (sessions.length > 0) {
            Promise.all(sessions.map(session => {
                const formData = new FormData();
                formData.append('Title', session.Title.trim());
                formData.append('Description', session.Description.trim());
                formData.append('Tags', '');
                formData.append('Date', session.Date);
                formData.append('StartTime', session.StartTime);
                formData.append('EndTime', session.EndTime);
                formData.append('PlaylistId', courseId);
                formData.append('Filecontent', session.Thumbnail[0]);
                return API.createSessions(formData)
                    .then(res => {
                        if (res.status === 200) {
                            let sessionId = res.data.SessionId
                            let duration = Math.round((new Date(session.EndTime) - new Date(session.StartTime)) / 60000)
                            let endTimeDuration = Math.round((new Date(session.EndTime) - new Date()) / 3600000)
                            let payload = sessionPayload(userMail, liveCourseProviders.VideoSdk, getSessionPayloadBody(liveCourseProviders.VideoSdk, duration, session.Title.replace(/\s/g, "_"), endTimeDuration))
                            return sessionCreateLogicAppRequest(payload).then((sessionRes) => {
                                if (sessionRes.status === 200) {
                                    let roomId = sessionRes.data.body.roomId
                                    let token = sessionRes.data.token
                                    var updateSessionForm = new FormData()
                                    updateSessionForm.append('SessionId', sessionId);
                                    updateSessionForm.append('Date', session.Date);
                                    updateSessionForm.append('StartTime', session.StartTime);
                                    updateSessionForm.append('EndTime', session.EndTime);
                                    updateSessionForm.append('WebinarAccessToken', token);
                                    updateSessionForm.append('HostLink', webinarJoinLinks.HostLink + roomId + "/" + userName.split(' ').join('_'));
                                    updateSessionForm.append('ParticipantLink', webinarJoinLinks.ParticipantLink + roomId);
                                    API.updateSessions(updateSessionForm)
                                }
                            }).then(() => {
                                if (session.Document.length > 0) {
                                    var docCreate = {
                                        Title: session.Document[0].name.split('.')[0],
                                        Desctiption: 'test doc',
                                        SessionId: res.data.SessionId
                                    }
                                    return API.createSessionDocument(docCreate).then(doc => {
                                        if (doc.status === 200) {
                                            return Uploadwithsas(session.Document[0], doc.data.FileId, doc.data.DocumentSasToken, documentPath).then(sasRes => {
                                                if (sasRes.status === 201) {
                                                    return API.updateSessionDocument({
                                                        Id: doc.data.DocumentId,
                                                        FileName: session.Document[0].name,
                                                        Extension: session.Document[0].name.split('.')[1],
                                                        SessionId: res.data.SessionId
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
            })).then(() => {
                setAlert({ active: true, severity: 'success', message: 'Session created successfully' })
                setModalLoader(false)
                navigate('/collection/coursecollection')
            })
        }
        else {

        }
    }

    useEffect(() => {
        if ((isAddSessions && !editSessionOnList) || !isSesionsListActive) {
            setSessionDate(new Date().toLocaleDateString('en-CA'))
            setSessionStartTime(moment().add(15, 'minutes').format('HH:mm'))
            setSessionEndTime(moment().add(1, 'hour').add(15, 'minutes').format('HH:mm'))
        }
    }, [isAddSessions, isSesionsListActive])

    const handleSkip = () => {
        setSetionsList(true)
    }

    const handleSession = (isEdit = false) => {
        setEditSessionOnList(false);
        const regExp = /(\d{1,2})\:(\d{1,2})/;
        const startDate = sessionStartTime;
        const endDate = sessionEndTime;
        const currentTimeFormatted = moment().format("HH:mm");
        const selectedDate = moment(sessionDate);
        const isFutureDate = selectedDate.isAfter(moment(), 'day');
        
        const validationErrors = {
            nameError: false,
            descriptionError: false,
            thumbnailError: false,
            timeError: false,
            dateError: false,
            documentError: false,
        };
    
        const isValid = sessionName.trim().length > 0 &&
                        sessionName.trim().length <= validationLimits.sessionNameMax &&
                        sessionDescription.trim().length <= validationLimits.sessionDescriptionMax &&
                        sessionThumbnail.length > 0 &&
                        sessionDate.length > 0 &&
                        sessionStartTime.length > 0 &&
                        sessionEndTime.length > 0 &&
                        (isFutureDate || (startDate > currentTimeFormatted && endDate > currentTimeFormatted)) &&
                        parseInt(endDate.replace(regExp, "$1$2")) > parseInt(startDate.replace(regExp, "$1$2"));
    
        if (isValid) {
            setSetionsList(true);
            const model = {
                Title: sessionName.trim(),
                Description: sessionDescription.trim(),
                Date: new Date(sessionDate).toISOString(),
                StartTime: moment(new Date(sessionDate + " " + sessionStartTime.trim())).format(),
                EndTime: moment(new Date(sessionDate + "T" + sessionEndTime.trim())).format(),
                Document: sessionDocumnet,
                Thumbnail: sessionThumbnail,
                ThumbnailPreview: sessionThumbnailPreview,
            };
    
            if (isEdit) {
                sessions[sessionIndex] = model;
            } else {
                setSessions((prev) => [...prev, model]);
            }
        } else {
            if (sessionName.trim().length === 0 || sessionName.trim().length > validationLimits.sessionNameMax) {
                validationErrors.nameError = true;
            }
            if (sessionDescription.trim().length > validationLimits.sessionDescriptionMax) {
                validationErrors.descriptionError = true;
            }
            if (sessionThumbnail.length === 0) {
                validationErrors.thumbnailError = true;
            }
            if (!isFutureDate && (startDate < currentTimeFormatted || endDate < currentTimeFormatted)) {
                validationErrors.timeError = true;
            }
            if (parseInt(endDate.replace(regExp, "$1$2")) < parseInt(startDate.replace(regExp, "$1$2"))) {
                validationErrors.dateError = true;
            }
            if (sessionDocumnet.length > 0 && sessionDocumnet[0].name.trim().length > 25) {
                validationErrors.documentError = true;
            }
    
            setSessionNameError(validationErrors.nameError);
            setSessionDescriptionError(validationErrors.descriptionError);
            setSessionThumbnailError(validationErrors.thumbnailError);
            setSessionTimeError(validationErrors.timeError);
            setSessionDateError(validationErrors.dateError);
            setSessionDocumnetError(validationErrors.documentError);
        }
    };
    
    
    

    const handleEditSessionOnList = (data, idx) => {
        setSessionIndex(idx)
        setSessionName(data.Title)
        setSessionDescription(data.Description)
        setSessionDate(data.Date.split(' ')[0])
        setSessionDocumnet(data.Document)
        setSessionThumbnail(data.Thumbnail)
        setSessionThumbnailPreview(data.ThumbnailPreview)
        setSessionStartTime(moment(data.StartTime).format('HH:mm'))
        setSessionEndTime(moment(data.EndTime).format('HH:mm'))
        setSetionsList(false)
        setEditSessionOnList(true)
    }

    console.log(formFilled)

    const handleRemoveSessionOnList = (index) => {
        var updatedList = [...sessions];
        updatedList.splice(index, 1)
        setSessions(updatedList)
    }

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <CourseCreation 
                    ref={childRef} 
                    setAlert={setAlert} 
                    activeStep={activeStep}
                    formFilled = {setFormFilled}
                    onCourseIdReceived={handleCourseId}
                    setCourseType = {CourseType.Online}
                    setActiveStep = {setActiveStep} 
                    setSelectedCategoryId = {setSelectedCategoryId}/>
                );
            case 1:
                return (
                    <div>
                        {
                            !isSesionsListActive &&
                            <div>
                                <div className="quiz-text-input p-0">
                                    <h4>Session Title</h4>
                                    <input
                                        className={sessionNameError ? 'error' : ""}
                                        type="text"
                                        placeholder="Type your quiz title here"
                                        value={sessionName}
                                        onChange={(e) => {
                                            setSessionName(e.target.value)
                                            setSessionNameError(false)
                                        }} />
                                    {sessionNameError && <label className="question-title error-label"><p>Session title should be 1-50 characters</p></label>}
                                </div>
                                <input type='file' style={{ display: 'none' }}
                                    accept="image/*"
                                    ref={sessionThumbnailRef}
                                    onChange={(e) => {
                                        handleSessionThumbnail(e.target.files)
                                        setSessionThumbnailError(false)
                                    }} />
                                <div className="quiz-text-input p-0">
                                    {sessionThumbnailPreview.length === 0 &&
                                        <div className="video-upload thumb-upload webinar-upload-part" onClick={handleSessionThumbnailClick}>
                                            <svg width="36" height="36" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="64" height="64" rx="17.6" className="svg-for-menu" />
                                                <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z" className="svg-for-menu" />
                                            </svg>
                                            <h4>Upload thumbnail image</h4>
                                        </div>
                                    }
                                    {sessionThumnbnailError && <label className="question-title error-label"><p>Session thumbnail is required</p></label>}
                                </div>
                                {
                                    sessionThumbnailPreview.length > 0 &&
                                    <div className="replace-image-con quiz-text-input p-0">
                                        <img src={sessionThumbnailPreview} class="collection-thumb" />
                                        <button onClick={handleSessionThumbnailClick}>Replace image</button>
                                    </div>
                                }
                                <div className="quiz-text-input date-picker p-0">
                                    <h4>Date</h4>
                                    <div>
                                        <input
                                            type="date"
                                            min={new Date().toLocaleDateString('en-CA')}
                                            value={sessionDate.length > 0 ? new Date(sessionDate).toLocaleDateString('en-CA') : new Date().toLocaleDateString('en-CA')}
                                            onChange={(e) => {
                                                const selectedDate = new Date(e.target.value);
                                                const today = new Date();
                                                setSessionDate(e.target.value);
                                                if (selectedDate > today) {
                                                    setSessionDateError(false);
                                                    setSessionTimeError(false);
                                                } else {
                                                    setSessionDateError(true);
                                                    setSessionTimeError(true);
                                                }
                                            }}
                                            
                                            onClick={(event) => { event.target.showPicker() }}
                                        />
                                    </div>
                                </div>
                                <div className="quiz-text-input d-flex w-100 gap-2 date-picker p-0">
                                    <div className="w-50">
                                        <h4>Start Time</h4>
                                        <div>
                                            <input type='time' value={sessionStartTime} onChange={(e) => {
                                                setSessionStartTime(e.target.value)
                                                setSessionDateError(false)
                                                setSessionTimeError(false)
                                            }} onClick={(event) => { event.target.showPicker() }} />
                                        </div>
                                    </div>
                                    <div className="w-50">
                                        <h4>End Time</h4>
                                        <div>
                                            <input type='time' value={sessionEndTime} onChange={(e) => {
                                                setSessionEndTime(e.target.value)
                                                setSessionDateError(false)
                                                setSessionTimeError(false)
                                            }} onClick={(event) => { event.target.showPicker() }} />
                                        </div>
                                    </div>
                                </div>
                                {sessionDateError && <label className="question-title error-label"><p>Start Time cannot be after End time</p></label>}
                                {sessionTimeError && <label className="question-title error-label"><p>Selected Time cannot be less than current time</p></label>}
                                <div class="quiz-creator-con d-block p-0 mt-4">
                                    <h4>Description</h4>
                                    <textarea id="video-descripation" rows="10" cols="50" form="usrform" value={sessionDescription} placeholder="Start writing about the session here....." name="description" onChange={(e) => {
                                        setSessionDescription(e.target.value)
                                        setSessionDescriptionError(false)
                                    }} ></textarea>
                                    {sessionDescriptionError && <label className="question-title error-label"><p>Session title should be less than 1000 characters</p></label>}
                                </div>
                                <input type='file' style={{ display: 'none' }} ref={documentRef}
                                    accept={`application/*`}
                                    onChange={(e) => handleDocumentUpload(e.target.files)} />
                                <div class="quiz-creator-con d-block p-0 mt-4">
                                    <h4>Documents</h4>
                                    <div style={{ display: 'flex' }}>
                                        {
                                            sessionDocumnet.length > 0 &&
                                            <div className={`dropfile ${window.document.body.dir === "ltr" ? "file-marg-r" : "file-marg-l"}`}>
                                                <div className='documents'>
                                                    <div className="lms-tabcardspace">
                                                        <img
                                                            style={{ width: '30%' }}
                                                            src={extension.find(a => a.exstension.includes(sessionDocumnet[0].name.split('.')[1].trim())).src}
                                                            alt=""
                                                        ></img>
                                                    </div>
                                                    <div className="card-heading card-heading-m">
                                                        {sessionDocumnet[0].name.split('.')[0].trim()}
                                                    </div>
                                                    <div className="lms-flex-between lms-datespace">
                                                        <div className="card-date lms-carddate">
                                                            {moment(sessionDocumnet[0].lastModifiedDate).format("DD/MM/YYYY")}
                                                        </div>
                                                        <button className="doc-button">{extension.find(a => a.exstension.includes(sessionDocumnet[0].name.split('.')[1].trim())).exstension}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="dropfile">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className="svg-for-menu">
                                                <rect width="31" height="31" rx="8.448" className="svg-for-menu" />
                                                <rect x="8.91211" y="7.36243" width="12.4" height="16.275" fill="white" />
                                                <path className="svg-for-menu" fill-rule="evenodd" clip-rule="evenodd" d="M7.82408 6.19995C12.9407 6.19995 18.0578 6.19995 23.1744 6.19995C24.0683 6.19995 24.7992 6.9309 24.7992 7.82481C24.7992 12.9415 24.7992 18.0585 24.7992 23.1751C24.7992 24.069 24.0683 24.8 23.1744 24.8C18.0577 24.8 12.9407 24.8 7.82408 24.8C6.93017 24.8 6.19922 24.069 6.19922 23.1751C6.19922 18.0585 6.19922 12.9414 6.19922 7.82481C6.19922 6.9309 6.93017 6.19995 7.82408 6.19995ZM19.8951 12.9127C19.4453 13.3573 18.9956 13.8022 18.5458 14.2468C17.8484 13.5574 17.1506 12.868 16.4532 12.1786C16.4532 15.5964 16.4532 19.0147 16.4532 22.4326C15.817 22.4326 15.1811 22.4326 14.5448 22.4326C14.5448 19.0143 14.5448 15.5964 14.5448 12.1782C13.8474 12.8676 13.1496 13.5574 12.4522 14.2468C12.0024 13.8022 11.5527 13.3573 11.1029 12.9127C12.5684 11.464 14.0335 10.0157 15.499 8.56697C16.9645 10.0157 18.4296 11.464 19.895 12.9127H19.8951Z" />
                                            </svg>
                                            <p className="drop-text"></p>
                                            <p className="drop-text">Upload a document</p>
                                            <label htmlFor="drag-file" className="pri-button" onClick={handleDocumnetOpen}>Choose a file</label>
                                            {/* <input id = "drag-file" type="file" onChange={this.handleChange}></input> */}
                                        </div>
                                    </div>
                                </div>
                                {sessionDocumnetError && <label className="question-title error-label"><p>Document Name should be less than 25 characters</p></label>}
                            </div>
                        }

                        {isSesionsListActive &&
                            (!modalLoader ? <>
                                <div className='list-items-padd'>
                                    <ul>
                                        {
                                            sessions.map((session, idx) => {
                                                return (
                                                    <li className='course-session-list' key={idx}>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='course-sess-img'>
                                                                    <img src={session.ThumbnailPreview} class="collection-thumb" />
                                                                </div>
                                                                <div className='course-sess-content'>
                                                                    <h4>{session.Title}</h4>
                                                                    <div className='d-flex align-items-center'>
                                                                        <p>{moment(session.Date).format('Do MMM , YYYY')}</p>
                                                                        <p>{`${moment(session.StartTime).format('hh:mm a')} - ${moment(session.EndTime).format('hh:mm a')}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center gap-1'>
                                                                <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none" onClick={() => handleEditSessionOnList(session, idx)}>
                                                                    <rect class="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" strokeWidth="0.9"></rect>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8"></path>
                                                                    <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8"></path>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" class="edit-icon-svg"></path></svg>
                                                                <svg id="del-icon" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemoveSessionOnList(idx)}><rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" class="del-stroke" strokeWidth="0.9"></rect>
                                                                    <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white"></path>
                                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                    <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" class="del-svg "></path>
                                                                    <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white"></path><path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" ></path>
                                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div class="add-session-btn"><button class="primary-button sec-btn" onClick={() => { setSetionsList(false), handleReset() }}>+ Add New Session</button></div>
                                </div>
                                <div class="fixed-btns model-padding d-flex justify-content-end">
                                    <button class="filter-clear-all mx-4" onClick={() => setDialog({
                                        Open: true,
                                        Header: 'Close',
                                        Body: 'Do you really want to close creating the sessions tab now. You will lost all the session details you have made till now until you save.',
                                        ButtoneName: 'Close',
                                        modelType: dialogType.modelClose
                                    })}>Cancel</button>
                                    <button class="filter-clear-search" onClick={handleCreateSessions}>Save</button>
                                </div>
                            </>
                                : <Spinner />)
                        }
                    </div>
                );
            default:
                return (
                    <div>
                        <h1>Unknown Step</h1>
                    </div>
                );
        }
    };

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            severity: '',
            message: ''
        })
    };

    React.useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);


    const handleNext = () => {
        if (activeStep === 0) {
            triggerChildCreateCourse();
        }
        
        if (activeStep === 1) {
            if (sessions.length > 0 && isSesionsListActive) {
                handleCreateSessions();
            }
    
            handleSession(editSessionOnList);
        }
    };
    
    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
                    {alert.message}
                </Alert>
            </Backdrop>
            <div className="video-tab-con">
            <BreadcrumbsNav
                activeTtile="Create Live Course"  
                setStudioLandingPage={props.setStudioLandingPage}  
            />
                <HorizontalStepper steps={['Course Details ', 'Add Sessions']} activeStep={activeStep} />
                <div className='d-flex justify-content-center'>
                    <div className='upload-con-width upload-white-bg p-5 studio-course-creation'>
                        {renderStepContent(activeStep)}
                        <div className="fixed-btns model-padding d-flex justify-content-between">
                            <button onClick={handleBack} disabled={activeStep === 0} className="filter-clear-all mx-4">Back</button>
                            <div>
                            <button  disabled = {!sessions.length > 0 || isSesionsListActive } onClick={handleSkip} className="filter-clear-all mx-4">
                                Skip
                            </button>
                            <button disabled = {!formFilled} onClick={handleNext} className="filter-clear-search">{activeStep === 1 ? "Save" : "Next"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    );
};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(
        {
            fetchAdminCourseAction: fetchAdminCourse
        },
        dispatch
    );
};

const mapStateToProps = (state) => ({
    courses: state.courses.courses.Playlist,
    playlistCategories: state.category.playlistCategories,
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveCourseForm);
