import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import store from "../../../../src/store/configureStore";
import { courseContentType } from "../../../components/Common/Constants";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Selectcategory(props) {
  const [personName, setPersonName] = React.useState('');
  const [points, setPoints] = React.useState('');
  const categories = useSelector(() => store.getState().category.categories);

  React.useEffect(() => {
    if (props.Id !== undefined) {
      const data = props.selector === courseContentType ? categories.filter(a => a.Id === props.Id.PlalistCategory)[0] : props.Id
      setPersonName(data.CategoryName)
    }
  }, [props.Id])


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      event.target.value
    );
    props.onSeletecCategoryId(categories.filter(i => i.CategoryName.toString().toLowerCase() == value.toString().toLowerCase()).map((j) => j.Id)[0])
  };

  return (
    <div className="m-30 admin-vedio-select-option d-flex gap-1">
      <FormControl className="vertical-form-controll">
        <InputLabel id="multiple-checkbox-label" className="select-catagery">
        Select Category
        </InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Select Category" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
          className="select-option"
        >
          {categories.map((category) => (
            <MenuItem key={category.Id} value={category.CategoryName}>
              <Checkbox checked={personName.indexOf(category.CategoryName) > -1}  sx={{display:"none"}}/>
              <ListItemText primary={category.CategoryName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <input
        type="number"
        placeholder="Points for the Video"
        className="select-cat-upload px-4"
        value={points}
        onChange={(e) => {
          setPoints(e.target.value);
          props.videoPoints(e.target.value);
        }}
      />
    </div>
  );
}
