import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import ProgressBar from "../Common/ProgressBar";
import { Avatar, Stack } from "@mui/material";
import GenerateProfilePic from "../Common/GenerateProfilePictureIntitals";
import ErrorTab from "../Common/ErrorTab";
import VideoUploadedDate from "../Common/VideoUploadedDate";
import VideoExpireTime from "../Common/VideoExpireTime";

export const SavedCoursesList = (props) => {

  if (props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((course, idx) => {
          const [borderColor, setBorderColor] = React.useState('#3ADDC8');
          const borderStyle = course.WatchExpirationDateTime ? { border: `2px solid ${borderColor}` } : {};
          return (
            <li className="card-space" key={idx} style={borderStyle}>
              <Link to={"/course/" + course.PlaylistId + "/0"} >
                <div className="card-img">
                  <img
                    src={course.ThumbnailUrl}
                    alt="lms-logo" />
                </div>
                <ProgressBar className="in-progress-bar" value={course.PercentageOfCourseCompleted}></ProgressBar>
                <div className="card-info lms-flex-between">
                  <p >
                    {course.PlaylistCategoryName}
                  </p>
                  <div className="card-date">
                    {course.WatchExpirationDateTime === null ?
                      <VideoUploadedDate createdDate={course.CreatedDate} /> :
                      <VideoExpireTime expirationTime={course.WatchExpirationDateTime}
                        onBorderColorChange={setBorderColor} />}
                  </div>
                </div>
                <div className="card-heading">{course.Title}</div>
                <div className="card-para">{course.Description}</div>

                {props.type !== 'save' && <div style={{ marginTop: 10 }}>
                  <Stack direction="row" spacing={2} >
                    {
                      props.type === 'sharebyyou' ?
                        <Avatar
                          alt="Remy Sharp"
                          src={course.SharedWithProfilePictureUrl === '' || course.SharedWithProfilePictureUrl === null ? GenerateProfilePic(course.SharedWithName) : process.env.REACT_APP_AZURE_BLOB_BASE_URL + '/cdn' + course.SharedWithProfilePictureUrl}
                          sx={{ width: 25, height: 25 }}
                        />
                        :
                        <Avatar
                          alt="Remy Sharp"
                          src={course.SharedByProfilePictureUrl === '' || course.SharedByProfilePictureUrl === null ? GenerateProfilePic(course.SharedByName) : process.env.REACT_APP_AZURE_BLOB_BASE_URL + '/cdn' + course.SharedByProfilePictureUrl}
                          sx={{ width: 25, height: 25 }}
                        />
                    }
                    <div className="identify" style={{ marginLeft: 7 }}>{props.type === 'sharebyyou' ? course.SharedWithName : course.SharedByName}</div>
                  </Stack>
                </div>}
              </Link>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else {
    return (
      <div id="no-result-saved-item">
        <h1>No Courses are available</h1>
        <p>Please search with alternative keyword</p>
      </div>
    )
  }
};

export default SavedCoursesList;
